<template>
  <div>
    <vue-excel-editor v-model="jsondata" filter-row width="100%">
      <vue-excel-column
        field="user"
        label="User ID"
        type="string"
        width="80px"
      />
      <vue-excel-column field="name" label="Name" type="string" width="150px" />
      <vue-excel-column
        field="phone"
        label="Contact"
        type="string"
        width="130px"
      />
      <vue-excel-column
        field="gender"
        label="Gender"
        type="select"
        width="50px"
        :options="['F', 'M', 'U']"
      />
      <vue-excel-column field="age" label="Age" type="number" width="70px" />
      <vue-excel-column
        field="birth"
        label="Date Of Birth"
        type="date"
        width="180px"
      />
      <vue-excel-column
        field="address"
        label="Address"
        type="string"
        width="350px"
      />
      <vue-excel-column
        field="comment"
        label="Comment"
        type="date"
        width="200px"
      />
    </vue-excel-editor>
  </div>
</template>
<script>
export default {
  name: "tp-base",
  data: (vm) => ({
    jsondata: [
      {
        user: "hc",
        name: "Harry Cole",
        phone: "1-415-2345678",
        gender: "M",
        age: 25,
        birth: "1997-07-01",
        address: "123 Main St, San Francisco, CA 94122",
      },
      {
        user: "sm",
        name: "Simon Minolta",
        phone: "1-123-7675682",
        gender: "M",
        age: 20,
        birth: "1999-11-12",
        address: "132 馬公市林森街28號8樓",
      },
      {
        user: "ra",
        name: "Raymond Atom",
        phone: "1-456-9981212",
        gender: "M",
        age: 19,
        birth: "2000-06-11",
        address: "台北市中正區忠孝西路一段1號",
      },
      {
        user: "ag",
        name: "Mary George",
        phone: "1-556-1245684",
        gender: "F",
        age: 22,
        birth: "2002-08-01",
        address: "360 大里縣大仁路3號之8",
      },
      {
        user: "kl",
        name: "Kenny Linus",
        phone: "1-891-2345685",
        gender: "M",
        age: 29,
        birth: "1990-09-01",
      },
    ],
  }),
};
</script>
